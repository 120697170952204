/* App.css */

/* General styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-content {
  margin-left: 250px; /* Ensure content does not overlap side panel */
  padding: 20px;
  width: calc(100% - 250px); /* Adjust width considering side panel */
  height: 100%;
}

.side-panel {
  width: 21rem; /* Adjust width as needed */
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #20202005;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

/* Responsive typography */
h1 {
  font-size: 1rem; /* Base size for h1 */
}

p {
  font-size: 0.5rem; /* Base size for paragraphs */
}

/* Responsive typography adjustments */
@media (min-width: 576px) {
  h1 {
    font-size: 2.5rem;
  }
  p {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
  p {
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 3.5rem;
  }
  p {
    font-size: 1.3rem;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 4rem;
  }
  p {
    font-size: 1.4rem;
  }
}

/* Text areas */
.text-area {
  padding: 5% 20%;
}



.text-area-black {
  width: 100%;
  background-color: black;
  color: white; 
}

.text-area-end {
  display: flex;
  justify-content: flex-end;
  text-align: end;
}

/* Image overlays */
.image-container {
  position: relative;
  display: inline-block;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.623), rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.616));
}

.image_random {
  width: 100%;
}

.container_random {
  position: relative;
  width: 100%;
}

.image-container_random {
  position: relative;
  display: inline-block;
}

.overlay_random {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 5%;
  width: 45%;
  text-shadow: 0 0 300px rgba(255, 255, 255, 0.895);
}

.overlay_mir {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 5%;
  width: 45%;
  text-shadow: 0 0 300px rgba(255, 255, 255, 0.895);
}

.overlay-text_random {
  margin: 0;
  color: #ffffff;
}

/* Keyframes for animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
